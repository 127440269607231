import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Gallery from '../../components/Gallery';

export default function Civic() {
  const data = useStaticQuery(graphql`
    query CivicQuery {
      allCloudinaryMedia(
        filter: { tags: { eq: "civic" } }
        sort: { fields: public_id, order: ASC }
      ) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return (
    <Gallery
      galleryImages={data.allCloudinaryMedia.nodes}
      galleryName="Civic"
      title="Civic Gallery | Van Gemert Memorials"
      description="Photo gallery of items that we have created for the community."
      urlPath="civic"
    />
  );
}
